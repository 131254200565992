export default defineI18nConfig(() => ({
  legacy: false,
  messages: {
    en: {
      donations: 'Donations',
      book: 'Book a Walkthrough',
      mfa_link: 'Setup {link} to enable Withdrawals and Conversions',
      home: {
        title: 'Home',
        description: 'To update account information please visit the settings tab'
      },
      payment: {
        title: 'Payment Widget',
        title_text: 'Copy & Paste this code on your website.',
        link: 'Payment Link',
        link_text: 'Share this link with your supporters. Perfect for emails and social media.'
      },
      menu: {
        clients: 'Clients',
        withdrawal: 'Withdrawals',
        home: 'Home',
        donations: 'Donations',
        contributions: 'Contributions',
        finance: 'Finance',
        settings: 'Settings'
      },
      withdrawal: {
        title: 'Withdrawals',
        requests: 'Withdrawals Requests'
      },
      settings: {
        title: 'Settings',
        lets_setup: 'Settings',
        ein: 'EIN Number',
        fee: 'Fee',
        company_name: 'Business Legal Name',
        creator_name: 'Channel name',
        creator_name_holder: 'Enter Your Channel name',
        company_name_holder: 'Enter Your Legal Business Name',
        name_as: 'Business Name (Doing Business As)',
        name_as_holder: 'Enter Your Business Name',
        receive_notification: 'Receive donation notification',
        details: 'Business Details',
        creator: 'Creator Details'
      },
      confirm: {
        mfa_reset_question: 'Are you sure you want to reset MFA? This action cannot be undone',
        yes: 'Yes, Reset MFA',
        no: 'No, Take Be Back'
      },
      campaign_transactions: 'Campaign Transactions',
      back: 'Back',
      send: 'Send',
      export: 'Export Page',
      invite: 'Invite',
      provision: 'Provision',
      provision_pool: 'Provision Default Pool',
      status: 'Status',
      re_provision: 'Re-Provision',
      admin: {
        title: 'Clients',
        vaults_status: 'Vaults Status',
        name: 'Name',
        type: 'Account Type',
        total: 'Total Donations (USD)',
        setup: 'Account Setup',
        status: 'Approval Status',
        status_user: 'Status',
        details: 'Details',
        setup_t: 'Setup',
        pending: 'Pending',
        complete: 'Setup Complete',
        users: 'Users',
        mail: 'Email'
      },
      contributors: {
        title: 'Contributors',
        name: 'Name',
        mail: 'Email',
        transactions: 'Transactions'
      },
      clients: {
        title: 'Clients',
        title_single: 'Client',
        fireblocks: 'Fireblocks',
        staking: 'Staking Pool',
        create: 'Onboard New Client',
        details: 'Organization Details',
        users: 'Users',
        approve_label: 'Approve Organization',
        send_widget: 'Send Widget',
        approve: 'Approve',
        deny: 'Deny',
        transactions: 'Transactions',
        donations: 'Donations',
        stripe: 'Stripe'
      },
      stripe: {
        title: 'Stripe Connect',
        in_progress: 'In Progress',
        not_connected: 'Not Connected',
        connected: 'Connected'
      },
      widget: {
        title: 'Anonymous Donations',
        payment: 'Payment Methods',
        payment_text:
          'Enable payment method option if you wish to allow it to be used for donations. Turn it off to hide the option.',
        payment_text_note: 'Note: At least one method should be enabled.',
        text:
          'Enable this option if you wish to allow anonymous donations. Turn it off to require donor\n' +
          '        identification.'
      },
      logout: 'Logout'
    }
  }
}));
